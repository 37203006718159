<template>
  <div>
    <cta-banner :title="$route.name"/>
    <cta-sponsors :sponsors="sponsors" :header="headerPatrocinadores" />
  </div>
</template>

<script>
import API from "@/api";
import CtaBanner from '../../components/common/CtaBanner.vue';
import CtaSponsors from "@/components/CtaSponsors";
import {mapState} from "vuex";

export default {
  name: "Patrocinadores",
  components: {
    CtaBanner,
    CtaSponsors
  },
  data() {
    return {
      sponsors: [],
      headerPatrocinadores: {},
    }
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  created () {
    this.getSponsors();
    this.getInfo();
  },
  methods: {
    async getSponsors() {
      const { data } = await API.getSponsors({ evento_fk: this.idEventoActual });
      this.sponsors = data.results.sort((a, b) => a.orden - b.orden);
    },
    async getInfo(){
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.headerPatrocinadores = (items[i].codigo === 'PA01') ? items[i] : this.headerPatrocinadores;
      }
    },
  },
}
</script>

<style>

</style>
